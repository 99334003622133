export const stackedBarChartOptionsDashboard3 = {
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: true
    },
    dropShadow: {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
  }
  },
  tooltip: {
    theme: "dark",
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }],
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 2,
      // barHeight: 30, // Adjust the height of the bars
      dataLabels: {
        enabled: false,
        total: {
          enabled: true,
          offsetX: 10,
          style: {
            fontSize: '13px',
            fontWeight: 'normal',
            color: '#dfe6e9',
          }
        },
        formatter: function (val,opts) {
          return val.toFixed(2) + " %"
        },
      }
    },
  },
  xaxis: {
    type: 'text',
    categories: ['Dec 2022', 'Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 
                'May 2023', 'Jun 2023', 'Jul 2023', 'Aug 2023', 'Sep 2023', 
                'Oct 2023', 'Nov 2023', 'Dec 2023'],
    max: 30000,
    labels: {
      style: {
        colors: '#dfe6e9', // Set x-axis text color to white
      },
      formatter: function (value) {
        // Use toFixed(2) to display only two decimal points
        return value === 0 ? value : value/1000 + "k";
      },
    }, 
  },
  yaxis: {
    labels: {
      style: {
        colors: '#dfe6e9', // Set x-axis text color to white
      },
    },  
  },
  legend: {
    horizontalAlign: 'left',
    position: 'top',
    labels: {
      colors: '#fff', // Set legend text color to white
      align: 'left',
    }, 
    offsetY: 10
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#56577A",
  },
  fill: {
    opacity: 0.8
  },
  colors: ["#675500", '#C7AA44', '#f9ca24', "#ff8b00", "#FAE523"]
}