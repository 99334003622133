import React from 'react';
import Chart from 'react-apexcharts';

const HeatmapChart = (data) => {
  const options = {
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    legend: {
      markers: {
        fillColors: ['#C0E3FF', '#92CFFF', '#1398FF', '#005597'],
        radius: 3  // Ensures square markers
      }
    },
    tooltip: {
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          // Customize the tooltip text here
          return `${value} min`;
        },
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              name: 'low',
              color: '#C0E3FF'
            },
            {
              from: 1,
              to: 10,
              name: 'medium',
              color: '#92CFFF'
            },
            {
              from: 11,
              to: 20,
              name: 'high',
              color: '#1398FF'
            },
            {
              from: 21,
              to: 40000000000,
              name: 'extreme',
              color: '#005597'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Average service time by speciality'
    },
    xaxis: {
      categories: data.categories,
      labels: {
      }
    },
  };

  console.log("The heatmapchart options: ", options);
  return (
    <div id="chart">
      <Chart options={options} series={data.series} type="heatmap" height={350} />
    </div>
  );
};

export default HeatmapChart;
