export const stackedBarChartOptionsDashboard2 = {
  chart: {
    type: 'bar',
    height: '100%',
    stacked: true,
    toolbar: {
      show: false
    },
    zoom: {
      enabled: true
    },
    dropShadow: {
      enabled: false,
      enabledOnSeries: undefined,
      top: 0,
      left: 0,
      blur: 3,
      color: '#000',
      opacity: 0.35
  }
  },
  tooltip: {
    theme: "dark",
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 2,
      // columnWidth: 50,
      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: '13px',
            fontWeight: 'normal',
            color: '#dfe6e9'
          }
        }
      }
    },
  },
  colors: ['#B59500', '#f9ca24'],
  xaxis: {
    // type: 'text',
    categories: [],
    min: 0,
    max: 1,
    labels: {
      style: {
        colors: '#dfe6e9', // Set x-axis text color to white
      },

    },  
  },
  yaxis: {
    labels: {
      style: {
        colors: '#dfe6e9', // Set x-axis text color to white
      },
    },  
  },
  legend: {
    horizontalAlign: 'left',
    position: 'top',
    labels: {
      colors: '#fff', // Set legend text color to white
      align: 'left',
    }, 
  },
  grid: {
    strokeDashArray: 5,
    borderColor: "#56577A",
  },
  fill: {
    opacity: 0.8
  }
}