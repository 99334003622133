import React from 'react';
import Chart from 'react-apexcharts';


class Treemap extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      chartData: [],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          toolbar: {
            show: false
          },
          height: 350,
          type: 'treemap'
        },
        title: {
          text: '',
          align: 'left'
        },
        colors: [
          '#FEC047',
          '#FEC961',
          '#FED27B',
          '#1AE6A1',
          '#51ECB7',
          '#6BEFC2',
          '#1799FB',
          '#42ACFC',
          '#89CBFD',
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
            stroke: {
              width: 10,
              color: '#fff', // White color to create spacing effect
            },
          }
        }
      },
    };
  }
  
  componentDidMount() {
    const { data } = this.props;
    
    this.setState({
      chartData: data,
    });
  }
  
  render() {
    return (
      <div id="chart">
      <Chart options={this.state.chartOptions} series={this.props.data} type="treemap" height={350} width={'100%'} />
    </div>
    )
  }
}
export default Treemap;