import React from 'react'

const Doctor = () => {
  return (
    <div style={{position: 'absolute', bottom: '10px', right: '16px'}}>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100px" height="100px" viewBox="0 0 600.000000 600.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,600.000000) scale(0.10000,-0.10000)"
fill="white" opacity={0.25} stroke="none">
<path d="M2370 5110 c-323 -45 -583 -173 -811 -400 -122 -120 -194 -224 -235
-337 l-28 -78 -4 -448 c-2 -374 0 -463 13 -539 30 -175 100 -347 205 -504 69
-103 233 -266 338 -335 l82 -54 -2 -189 -3 -189 -480 -107 c-517 -116 -578
-134 -712 -222 -137 -89 -246 -208 -323 -351 -45 -83 -410 -1161 -410 -1210 0
-73 75 -147 150 -147 49 0 107 35 128 78 11 20 99 276 196 568 180 541 201
592 283 692 46 56 140 126 218 163 60 28 300 91 310 81 3 -3 5 -152 5 -332 l0
-327 -66 -33 c-129 -63 -231 -204 -254 -349 -49 -319 246 -597 562 -530 190
41 341 204 369 399 27 191 -80 396 -249 476 l-61 29 0 370 -1 370 205 46 c144
32 208 42 214 35 5 -6 102 -166 216 -356 114 -190 218 -357 233 -373 50 -52
146 -55 199 -5 14 12 122 185 240 383 119 198 217 361 217 361 1 1 94 -19 209
-44 l207 -46 0 -207 0 -206 -45 -13 c-135 -38 -295 -175 -364 -312 -63 -125
-73 -197 -69 -537 3 -264 4 -281 24 -307 46 -62 65 -69 197 -72 102 -2 128 0
157 15 126 65 97 257 -42 280 l-40 6 4 201 c3 197 4 202 32 263 32 69 89 129
158 164 64 33 185 38 259 10 79 -30 141 -88 181 -169 l33 -67 4 -201 3 -201
-39 -6 c-140 -23 -169 -215 -43 -280 29 -15 55 -17 157 -15 132 3 152 10 197
72 20 26 21 40 21 339 0 267 -3 321 -18 376 -54 197 -211 368 -400 436 l-62
23 -3 163 c-1 89 1 166 5 170 12 11 261 -55 318 -83 73 -38 166 -109 208 -160
82 -101 103 -150 288 -707 100 -300 189 -556 197 -569 19 -30 83 -62 122 -62
75 0 150 74 150 147 0 37 -359 1111 -395 1183 -112 222 -309 397 -540 479 -33
12 -269 68 -525 125 l-465 103 -2 189 -3 189 83 55 c103 68 267 231 336 333
104 156 176 331 206 505 13 76 15 165 13 539 l-4 448 -28 78 c-41 113 -113
217 -235 338 -201 199 -426 321 -703 380 -107 23 -383 33 -488 19z m374 -305
c124 -19 271 -68 370 -125 164 -94 324 -248 384 -370 26 -53 27 -63 30 -235
l4 -179 -125 121 c-168 164 -174 165 -353 88 -46 -20 -128 -48 -181 -62 -92
-25 -112 -27 -313 -27 -201 0 -221 2 -313 27 -53 14 -135 42 -181 62 -180 77
-186 76 -353 -88 l-123 -120 0 162 c0 229 23 287 179 441 252 250 605 361 975
305z m-694 -1015 c181 -62 263 -74 505 -75 246 0 304 8 503 71 l112 36 100
-96 c81 -77 116 -103 181 -133 l81 -38 -5 -105 c-18 -383 -294 -734 -672 -853
-344 -108 -714 -21 -970 228 -178 174 -281 393 -292 625 l-5 105 81 38 c66 30
100 56 179 132 53 52 101 94 106 95 5 0 48 -14 96 -30z m262 -1517 c53 -12
120 -16 248 -16 128 0 195 4 248 16 40 9 75 17 78 17 2 0 4 -75 4 -167 l0
-167 -162 -273 c-90 -150 -165 -272 -168 -272 -3 0 -78 122 -167 272 l-163
273 0 167 c0 92 2 167 4 167 3 0 38 -8 78 -17z m-803 -1648 c127 -65 127 -241
0 -305 -48 -25 -92 -25 -145 -1 -127 58 -133 227 -10 302 38 23 115 25 155 4z"/>
</g>
</svg>
</div>
  )
}

export default Doctor
