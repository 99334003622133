/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import './index.css'
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import { IoTicketOutline } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import { IoIosTimer } from "react-icons/io";
import iconDoctor from 'assets/images/small-logos/icon-doctor.svg'
import Doctor from "./Doctor";



// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";
import Table from './Table'

// Vision UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// MediaQuery for current screen size info
import useMediaQuery from '@mui/material/useMediaQuery'

// Dashboard layout components
import WelcomeMark from "layouts/analytics/components/WelcomeMark";
import Projects from "layouts/analytics/components/Projects";
import OrderOverview from "layouts/analytics/components/OrderOverview";
import SatisfactionRate from "layouts/analytics/components/SatisfactionRate";
import ReferralTracking from "layouts/analytics/components/ReferralTracking";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import BarChart from "examples/Charts/BarCharts/BarChart";
import DonutChart from "examples/Charts/DonutCharts/DonutChart";
import { stackedBarChartDataDashboard } from "layouts/analytics/data/stackBarChartData";
import { stackedBarChartOptionsDashboard } from "layouts/analytics/data/stackBarChartOptions";
import { stackedBarChartDataDashboard2 } from "layouts/analytics/data/stackBarChartData2";
import { stackedBarChartOptionsDashboard2 } from "layouts/analytics/data/stackBarChartOptions2";
import { stackedBarChartDataDashboard3 } from "layouts/analytics/data/stackBarChartData3";
import { stackedBarChartOptionsDashboard3 } from "layouts/analytics/data/stackBarChartOptions3";
import { horizontalBarChartDataDashboard } from "layouts/analytics/data/horizontalBarChartData";
import { horizontalBarChartOptionsDashboard } from "layouts/analytics/data/horizontalBarChartOptions";
import { lineChartDataDashboard } from "layouts/analytics/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/analytics/data/lineChartOptions";
import { pieChartDataDashboard } from "layouts/analytics/data/pieChartData";
import { pieChartOptionsDashboard2 } from "layouts/analytics/data/pieChartOptions2";
import { barChartDataDashboard2 } from "layouts/analytics/data/barChartData2";
import { barChartOptionsDashboard2 } from "layouts/analytics/data/barChartOptions2";
import { donutChartDataDashboard } from "layouts/analytics/data/donutChartData";
import { donutChartOptionsDashboard } from "layouts/analytics/data/donutChartOptions";
import { donutChartOptionsDashboard2 } from "layouts/analytics/data/donutChartOptions2";
import { donutChartOptionsDashboard3 } from "layouts/analytics/data/donutChartOptions3";
import { donutChartOptionsDashboard4 } from "layouts/analytics/data/donutChartOptions4";
import { donutChartOptionsDashboard5 } from "layouts/analytics/data/donutChartOptions5";
import PieChart from "examples/Charts/PieCharts/PieChart";
import GaugeComponent from 'react-gauge-component'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CountUp from 'react-countup';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarFilled from '@mui/icons-material/DirectionsCarFilled'
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoomOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import StraightenIcon from '@mui/icons-material/Straighten';
import './test.css'
import RangeSlider from "./RangeSlider";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import boxShadow from "assets/theme/functions/boxShadow";
import Treemap from "examples/Charts/BarCharts/Treemap";
import HeatmapChart from "examples/Charts/HeatmapChart/HeatmapChart";
import HistogramChart from "examples/Charts/HistogramCharts/HistogramChart";
import CheckboxesTags from "./CheckboxesTags";
import axios from 'axios';

const getData = () => [
  {
    zone: "MAK1",
    tsd: 130625,
    cpp: 17,
    ozv: 2,
    cd: "Low",
    nrf: 11,
  },
  {
    zone: "MAK2",
    tsd: 326563,
    cpp: 23,
    ozv: 2,
    cd: "Low",
    nrf: 45,
  },
  {
    zone: "MAK3",
    tsd: 359219,
    cpp: 14,
    ozv: 3,
    cd: "Low",
    nrf: 68,
  },
  {
    zone: "GRAND1",
    tsd: 261250,
    cpp: 43,
    ozv: 4,
    cd: "Low",
    nrf: 67,
  },
  {
    zone: "GRAND2",
    tsd: 228594,
    cpp: 37,
    ozv: 3,
    cd: "Low",
    nrf: 89,
  },
  {
    zone: "AIRPORT",
    tsd: 120313,
    cpp: 26,
    ozv: 3,
    cd: "Low",
    nrf: 19,
  },
  {
    zone: "TRAIN",
    tsd: 30078,
    cpp: 16,
    ozv: 3,
    cd: "High",
    nrf: 19,
  },
  {
    zone: "HYWY",
    tsd: 42109,
    cpp: 16,
    ozv: 3,
    cd: "High",
    nrf: 48,
  },
  
];

const prepareTreemapData = (data) => {
  let newData = []
  for(let element in data){
    if(data.hasOwnProperty(element) && element !== ''){
      newData.push({"x": element, "y": data[element]});
    }
  }
  return newData;
}
const prepareLineData = (data) => {
  // Extract dates and counts from the data
  const dates2 = data.map(visit => visit.date);
  const counts2 = data.map(visit => visit.count);

   // Determine the interval for selecting data points
   const step = Math.max(1, Math.floor(data.length / 11));
   const selectedData = data.filter((_, index) => index % step === 0);

   if (selectedData.length > 12) {
     selectedData.length = 12;
   }

   const values = selectedData.map(item => item.count);
   const timestamps = selectedData.map(item => item.date);
  return {dates: timestamps, counts: values};
}

const prepareHeatmapData = (data) => {
  const specialtiesData = {};
  const allDatesSet = new Set();

  // Organize data by specialties and collect all dates
  data.forEach(item => {
    const { date, speciality, avg_serving_time } = item;

    if (!specialtiesData[speciality]) {
      specialtiesData[speciality] = [];
    }

    specialtiesData[speciality].push({ date, avg_serving_time });
    allDatesSet.add(date);
  });

  const allDates = Array.from(allDatesSet).sort((a, b) => new Date(a) - new Date(b));

  // Fill missing data points with zeros
  const filledSpecialtiesData = {};
  Object.keys(specialtiesData).forEach(speciality => {
    const dateMap = new Map(specialtiesData[speciality].map(item => [item.date, item.avg_serving_time]));
    const filledData = allDates.map(date => ({
      date,
      avg_serving_time: dateMap.get(date) || 0
    }));
    filledSpecialtiesData[speciality] = filledData;
  });

  const resultSpecialties = [];
  const resultDates = new Set();
  let maxServingTime = 0;

  Object.keys(filledSpecialtiesData).forEach(speciality => {
    const totalPoints = filledSpecialtiesData[speciality].length;
    const step = Math.max(1, Math.floor(totalPoints / 15));

    const selectedData = [];
    for (let i = 0; i < 16 && i * step < totalPoints; i++) {
      selectedData.push(filledSpecialtiesData[speciality][i * step]);
    }

    const servingTimes = selectedData.map(item => Math.floor(item.avg_serving_time));
    const dates = selectedData.map(item => item.date.slice(5));

    servingTimes.forEach(time => {
      if (time > maxServingTime) {
        maxServingTime = time;
      }
    });

    resultSpecialties.push({
      name: speciality,
      data: servingTimes
    });

    dates.forEach(date => resultDates.add(date));
  });
  // console.log("Inside Heatmap: ", maxServingTime, resultSpecialties, Array.from(resultDates).sort((a, b) => new Date(a) - new Date(b)))
  return {
    maxServingTime,
    specialties: resultSpecialties,
    heatmapDates: Array.from(resultDates).sort((a, b) => new Date(a) - new Date(b))
  };
}
function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [ totalAppointments, setTotalAppointments] = useState(0);
  const [ avgWaitingTime, setAvgWaitingTime] = useState(0)
  const [ avgServingTime, setAvgServingTime] = useState(0)
  const [treemapData, setTreemapData] = useState([])
  const [treemapDataOrg, setTreemapDataOrg] = useState([])
  const [demographic, setDemographic] = useState(0)
  const [histogramData, setHistogramData] = useState([])
  const [heatmapData, setHeatmapData] = useState([])
  const [heatmapCategories, setHeatmapCategories] = useState([])
  const [dates, setDates] = useState(null)
  const [filter, setFilter] = useState([])
  const [dateFilter, setDateFilter] = useState(['2024-01-01', '2024-01-30'])
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    if(dates !== null){
      const startDate = dates[0].toISOString().split('T')[0];
      const endDate = dates[1] === null ? startDate : dates[1].toISOString().split('T')[0];
      setDateFilter([startDate, endDate])
    }
    
  }, [dates])
  useEffect(() => {
    if(filter.length > 0){
      const filtered = treemapDataOrg.filter(item => filter.includes(item.x))
      setTreemapData(filtered)
    }else{
      setTreemapData(treemapDataOrg)
    }
    
  }, [filter])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [nationality_count, daily_visits, histogram, heatmap] = await Promise.all([
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/nationality_counts?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/daily_visits?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/visit_histogram?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/heatmap_data?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
        ]);
        setHistogramData(histogram.data.histogram_data)
        const newTreemap = prepareTreemapData(nationality_count.data.nationality_counts)
        const {maxServingTime, specialties, heatmapDates} = prepareHeatmapData(heatmap.data.heatmap_data)
        console.log("Heatmap: ", maxServingTime, specialties, heatmapDates)
        setHeatmapData(specialties)
        setHeatmapCategories(heatmapDates)
        const {dates, counts} = prepareLineData(daily_visits.data.daily_visits);
        setChart1Data([{data:counts}])
        setChart1Options(prev =>  ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories: dates,
          },
        }));
        console.log(newTreemap)
        setTreemapData(newTreemap)
      } catch (err) {
        // setError(err);
        console.log(err)
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [dateFilter])
  useEffect(() => {
    const fetchData = async () => {
      const api = demographic == 0 ? 'nationality_counts' : demographic == 1 ? 'age_group_counts' : demographic == 2 ? 'gender_counts' : 'speciality_counts'; 
      try {
        const [nationality_count] = await Promise.all([
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/${api}?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
        ]);
        const newTreemap = prepareTreemapData(nationality_count.data[api])
        setTreemapData(newTreemap)
        setTreemapDataOrg(newTreemap)
        // console.log(demographic)
      } catch (err) {
        // setError(err);
        console.log(err)
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [demographic])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, avg_waiting_time, avg_serving_time, nationality_count, daily_visits, histogram, heatmap] = await Promise.all([
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/appointments_count?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/average_waiting_time?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/average_serving_time?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/nationality_counts?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/daily_visits?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/visit_histogram?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
          axios.get(`https://api.mobisense.ai/api/v1/healthcare/heatmap_data?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`),
        ]);
        setTotalAppointments(response1.data.appointments_count)
        setAvgWaitingTime(avg_waiting_time.data.average_waiting_time_minutes)
        setAvgServingTime(avg_serving_time.data.average_serving_time_minutes)
        setHistogramData(histogram.data.histogram_data)
        const newTreemap = prepareTreemapData(nationality_count.data.nationality_counts)
        const {maxServingTime, specialties, heatmapDates} = prepareHeatmapData(heatmap.data.heatmap_data)
        console.log("Heatmap: ", maxServingTime, specialties, heatmapDates)
        setHeatmapData(specialties)
        const {dates, counts} = prepareLineData(daily_visits.data.daily_visits);
        setChart1Data([{data:counts}])
        setChart1Options(prev =>  ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories: dates,
          },
        }));
        console.log(newTreemap)
        setTreemapData(newTreemap)
        setTreemapDataOrg(newTreemap)
      } catch (err) {
        // setError(err);
        console.log(err)
      } finally {
        // setLoading(false);
      }
    };
    setDates([new Date(dateFilter[0]), new Date(dateFilter[1])])
    fetchData();
  }, []);

  // date range slider value
  const [value, setValue] = useState([1,12])
  const [month, setMonth] = useState(null);
  const [sMonth, setSMonth] = useState(new Date(2023, 0));
  const [eMonth, setEMonth] = useState(new Date(2023, 11));
  const [sMonthIndex, setSMonthIndex] = useState(1);
  const [eMonthIndex, setEMonthIndex] = useState(12);
  const months = ['Dec 2022', 'Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 
                  'May 2023', 'Jun 2023', 'Jul 2023', 'Aug 2023', 'Sep 2023', 
                  'Oct 2023', 'Nov 2023', 'Dec 2023']
  const bus = [47, 45, 44, 0, 39, 38, 0, 0, 0, 0, 43, 45, 46]
  const taxi = [0, 0, 0, 30, 0, 0, 25, 27, 28, 29, 0, 0, 0]

  const tapc = [15, 15, 14, 14, 13, 12, 12, 12, 13, 14, 14, 14, 15] // chart 3 time at passport control
  const tabr = [23, 22, 22, 21, 20, 19, 18, 19, 20, 20, 21, 22, 23] // chart 3 time at baggage reclaim

  const avtp = [16, 15, 15, 14, 13, 13, 12, 13, 13, 14, 15, 15, 16] // chart 4 average time spent

  const vCrater = [1850, 1895, 1875, 1702, 1609, 1513, 1487, 1480, 1855, 1707, 1633, 2028, 2183] // chart 6 crater
  const vEleph = [2670, 2351, 2526, 2148, 1963, 2203, 2064, 2223, 2214, 2196, 2257, 2586, 2374] // chart 6 crater
  const vHisto = [11174, 10667, 10549, 9835, 9210, 8841, 8125, 9464, 9097, 9445, 9867, 10907, 11449] // chart 6 historical
  const vMaraya = [3936, 3479, 3462, 3360, 3479, 3363, 2644, 2827, 3259, 3425, 3644, 3252, 3614] // chart 6 maraya
  const vOldTown = [9244, 8647, 7965, 7684, 7511, 7454, 6952, 7632, 7264, 7781, 7866, 8271, 8774] // chart 6 old town

  const [chart1Options, setChart1Options] = useState(lineChartOptionsDashboard);
  const [chart1Data, setChart1Data] = useState(lineChartDataDashboard);

  const [chart2Options, setChart2Options] = useState(stackedBarChartOptionsDashboard);
  const [chart2Data, setChart2Data] = useState(stackedBarChartDataDashboard);


  const [chart5Options, setChart5Options] = useState(barChartOptionsDashboard2);
  const [chart5Data, setChart5Data] = useState(barChartDataDashboard2);

  const [chart3Options, setChart3Options] = useState(stackedBarChartOptionsDashboard2);
  const [chart3Data, setChart3Data] = useState(stackedBarChartDataDashboard2);

  const [chart4Options, setChart4Options] = useState(horizontalBarChartOptionsDashboard);
  const [chart4Data, setChart4Data] = useState(horizontalBarChartDataDashboard);

  const [chart6Options, setChart6Options] = useState(stackedBarChartOptionsDashboard3);
  const [chart6Data, setChart6Data] = useState(stackedBarChartDataDashboard3);

  const borderRadius = 0; // cards' border radius

  const handleStartDate = (event) => {
    const inputDate = event;

    // Validate the input date
    if (!(inputDate instanceof Date) || isNaN(inputDate)) {
      console.warn('Invalid date. Please select a valid date.');
      return;
    }

    // Format the date as MM/YYYY
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();

    // Ensure the year is within the specified range (Dec/2022 to Dec/2023)
    if (year < 2022 || year > 2023) {
      console.warn('Invalid year. Please select a year between 2022 and 2023.');
      return;
    }

    // Calculate the month index
    let monthIndex = null;
    if(year === 2023){
      monthIndex = Number(month);
    }else if (year === 2022){
      monthIndex = 0;
    }
    console.log('Month Index:', monthIndex);
    setSMonthIndex(monthIndex);
    setSMonth(inputDate)
  };

  const handleEndDate = (event) => {
    const inputDate = event;

    // Validate the input date
    if (!(inputDate instanceof Date) || isNaN(inputDate)) {
      console.warn('Invalid date. Please select a valid date.');
      return;
    }

    // Format the date as MM/YYYY
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const year = inputDate.getFullYear();

    // Ensure the year is within the specified range (Dec/2022 to Dec/2023)
    if (year < 2022 || year > 2023) {
      console.warn('Invalid year. Please select a year between 2022 and 2023.');
      return;
    }

    // Calculate the month index
    let monthIndex = null;
    if(year === 2023){
      monthIndex = Number(month);
    }else if (year === 2022){
      monthIndex = 0;
    }
    console.log('Month Index:', monthIndex);
    setEMonthIndex(monthIndex);
  };

  // update start month, end month when ever slide range changed
  useEffect(() => {
    setSMonthIndex(value[0])
    setEMonthIndex(value[1])
  }, [value])
  // Effect to update state2 based on state1
  useEffect(() => {
    // when ever start & end months changes update categroy state
    if (sMonthIndex >= 0 && sMonthIndex <= 12 && eMonthIndex >= 0 && eMonthIndex <13){
      const newCats = months.slice(sMonthIndex, eMonthIndex + 1);

      //chart 5
      const newBus = bus.slice(sMonthIndex, eMonthIndex + 1);
      const newTxi = taxi.slice(sMonthIndex, eMonthIndex + 1);

      // Chart 3
      const newTapc = tapc.slice(sMonthIndex, eMonthIndex + 1);
      const newTabr = tabr.slice(sMonthIndex, eMonthIndex + 1);

      // chart 4
      const newAvtp = avtp.slice(sMonthIndex, eMonthIndex + 1);

      // chart 6
      const newVCrater = vCrater.slice(sMonthIndex, eMonthIndex + 1);
      const newVEleph = vEleph.slice(sMonthIndex, eMonthIndex + 1);
      const newVHisto = vHisto.slice(sMonthIndex, eMonthIndex + 1);
      const newVMaraya = vMaraya.slice(sMonthIndex, eMonthIndex + 1);
      const newVOldTown = vOldTown.slice(sMonthIndex, eMonthIndex + 1);

      setChart5Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));
      setChart3Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart4Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart6Options((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: newCats,
        },
      }));

      setChart5Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newBus} : {...item, data: newTxi}
        )
      );

      setChart3Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newTapc} : {...item, data: newTabr}
        )
      );

      setChart4Data((prev) => 
        prev.map((item, index) => 
          ({...item, data: newAvtp})
        )
      );

      setChart6Data((prev) => 
        prev.map((item, index) => 
          index === 0 ? {...item, data: newVCrater} 
          : index === 1 ? {...item, data: newVEleph}
          : index === 2 ? {...item, data: newVHisto}
          : index === 3 ? {...item, data: newVMaraya}
          : {...item, data: newVOldTown}
        )
      );
     const newSMonth = new Date(2022, 11+sMonthIndex, 1)
     setSMonth(newSMonth) 
     const newEMonth = new Date(2022, 11+eMonthIndex, 1)
     setEMonth(newEMonth) 
    }
  }, [sMonthIndex, eMonthIndex]); // Run this effect whenever state1 changes

  const columns = useMemo(
    () => [
      {
        Header: "Zone",
        accessor: "zone",
      },
      {
        Header: "Total Subscribers Detected",
        accessor: "tsd",
      },
      {
        Header: "Contacts per person",
        accessor: "cpp",
      },
      {
        Header: "Other Zones Visited",
        accessor: "ozv",
      },
      {
        Header: "Contact Duration",
        accessor: "cd",
      },
      {
        Header: "Normalized Risk Factor",
        accessor: "nrf",
      }
    ],
    []
  );

  const data = useMemo(() => getData(), []);

  return (
    <DashboardLayout>
      <DashboardNavbar month={month} setMonth={setMonth} />
      <VuiBox py={3} bgColor="white" sx={{minHeight: '100vh', borderRadius: '8px', marginLeft: '0 !important'}}>
      <VuiBox mb={3}>
      <Grid container spacing={2} justifyContent="flex-start" marginBottom={0}>
        <Grid item xs={12} sm={12} md={6} data-aos="fade-right" marginLeft={2}>
        <h2 style={{width:'auto', paddingLeft: 4}}>Monthly Overview</h2>
          
        </Grid>
        </Grid>
      </VuiBox>
      
      <VuiBox mb={15} sx={{width: '100%', overflow: 'hidden'}}>
      <Grid container spacing={2} justifyContent="flex-start" paddingX={2} width={'100%'}>
        <Grid item xs={12} sm={12} md={3} sx={{transition: 'height 2s ease-in-out', padding: 0}}>
          <div className="overviewCard blueCard">
            <div>
            Total Appointments
            </div>
          <div>
            <CountUp
              end={totalAppointments}
              duration={2}
              style={{fontWeight: 'bold', fontSize: '32px'}}
            />
          </div>
          <IoTicketOutline style={{color: 'white', fontWeight: 'bold', opacity: 0.25, fontSize: '72px', position: 'absolute', bottom: '10px', right: '16px'}}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} sx={{transition: 'height 2s ease-in-out'}}>
        <div className="overviewCard greenCard">
        <div>
          Missed Appointments
            </div>
          <div>
            <CountUp
              end={1890}
              duration={2}
              style={{fontWeight: 'bold', fontSize: '32px'}}
            />
          </div>
          <GiCancel style={{color: 'white', fontWeight: 'bold', opacity: 0.25, fontSize: '72px', position: 'absolute', bottom: '10px', right: '16px'}}/>
            
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} sx={{transition: 'height 2s ease-in-out'}}>
        <div className="overviewCard yellowCard">
            <div>
            Average Waiting Time
            </div>
          <div>
            <CountUp
              end={avgWaitingTime}
              duration={2}
              style={{fontWeight: 'bold', fontSize: '32px'}}
            />
            <span style={{marginLeft: 10}}>min</span>
          </div>
          <IoIosTimer style={{color: 'white', fontWeight: 'bold', opacity: 0.25, fontSize: '72px', position: 'absolute', bottom: '10px', right: '16px'}}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} sx={{transition: 'height 2s ease-in-out'}}>
        <div className="overviewCard grayCard">
            
            <div>
            Average Serving Time
            </div>
          <div>
            <CountUp
              end={avgServingTime}
              duration={2}
              style={{fontWeight: 'bold', fontSize: '32px'}}
            />
            <span style={{marginLeft: 10}}>min</span>
          </div>
          <Doctor />
          </div>
        </Grid>

      </Grid>
      </VuiBox>

      <VuiBox mb={0}>
      <Grid container spacing={2} justifyContent="flex-start" paddingX={2} width={'100%'}>
        <Grid item xs={12} sm={12} md={6} sx={{transition: 'height 2s ease-in-out', padding: 0}}>
          <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: 25, marginBottom: 25}}>
            <h2 style={{width:'auto'}}>Demographic Analysis</h2>
            <div className="flex flex-row gap-4 items-start">
            <CheckboxesTags data={treemapDataOrg} setData={setFilter} />
            <select className="selectBox" value={demographic} onChange={(e) => setDemographic(e.target.value)}>
              <option value="0">Nationality</option>
              <option value="1">Age</option>
              <option value="2">Gender</option>
              <option value="3">Speciality</option>
            </select>
            </div>
          </div>
          <Treemap data={[
    {
      data: treemapData,
    },
  ]} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{transition: 'height 2s ease-in-out', padding: 0}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h2 style={{width:'auto'}}>Trends over Time</h2>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
          <div style={{display: 'flex', flexDirection:'row'}}>
          <Calendar 
            minDate={new Date('2024-01-01')}
            maxDate={new Date('2024-06-31')}
            value={dates} 
            placeholder="Date Filter"
            onChange={(e) => setDates(e.value)} 
            selectionMode="range" 
            showIcon
            readOnlyInput
            hideOnRangeSelection
            style={{padding: '5px 10px'}}
          />
        </div>
        <VuiBox sx={{display: 'flex', justifyContent: 'right', textAlign: 'right', width: '100%'}}>
          {/* <RangeSlider value={value} setValue={setValue} /> */}
        </VuiBox>
          </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{marginBottom: 20}}>
            <LineChart 
                lineChartData={chart1Data}
                lineChartOptions={chart1Options}
              />
            </div>
            <div>
              <HistogramChart options={[]} data={histogramData} />
            </div>
          </div>
          {/* <HeatmapChart /> */}
        </Grid>
      </Grid>
      </VuiBox>

      <VuiBox mb={0}>
      <Grid container spacing={2} display={"flex"} justifyContent="flex-start" alignItems={'top'} paddingX={2} width={'100%'}>
      <Grid item xs={12} sm={12} md={6} sx={{transition: 'height 2s ease-in-out', padding: 0}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h2 style={{width:'auto'}}>Care Provider performance</h2>
        </div>
        {/* <HeatmapChart /> */}
          
          <HeatmapChart series={heatmapData} categories={heatmapCategories} />
        </Grid>

        <Grid display='flex' flexDirection={'column'} justifyContent={'start'} item xs={12} sm={12} md={6} sx={{transition: 'height 2s ease-in-out', padding: 0}}>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 50}}>
            <h2 style={{width:'auto'}}>Contact Tracing Summary</h2>
        </div>
        <Table columns={columns} data={data} />
        </Grid>
        </Grid>
      </VuiBox>

      </VuiBox> 
      
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
