import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function  CheckboxesTags(data) {
    const [elements, setElements] = React.useState([])
    React.useEffect(() => {
        console.log("The data: ", data)
        const mapped = data.data.map(element => element.x)
        setElements(mapped)
        
    }, [data])
    const handleChange = (event, value) => {
      console.log(value);
      data.setData(value)
    };
  return (
    <Autocomplete
      multiple
      className=''
      id="checkboxes-tags-demo"
      options={elements}
      onChange={handleChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        );
      }}
      style={{ width: 200 }}
      renderInput={(params) => (
        <TextField {...params} label="Filter" placeholder="choose.." />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
