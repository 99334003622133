import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const HistogramChart = (data) => {
  const static_options = {
    chart: {
        toolbar: {
            show: false,
        },
      type: 'bar',
      height: '50px',
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        borderRadius: 5, // Optional: to make the bars rounded
      },
    },
    grid: {
        strokeDashArray: 0,
        borderColor: "#EDEDED",
      },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: (val) => Number(val).toFixed(0),
      },
      title: {
        text: 'Number of Repetition',
      },
    },
    yaxis: {
      title: {
        text: 'Number of People',
      },
    },
    title: {
      text: 'Visits repetition',
      align: 'left',
    },
  };

  const series = [
    {
      name: 'Number of People',
      data: data.data.map((d) => d.people_count),
    },
  ];
  const categories = data.data.map((d) => d.visit_count.toString());

  return (
    <div>
      <Chart
        options={{ ...static_options, xaxis: { ...static_options.xaxis, categories } }}
        series={series}
        type="bar"
        height={250}
      />
    </div>
  );
}

export default HistogramChart;

/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
