// src/Table.js
import { useTable } from "react-table";
import ProgressBar from "@ramonak/react-progress-bar";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead style={{fontSize: "14px", textAlign: 'center', color: '#9E9E9E', borderTop: '1px solid #CDCDCD !important', borderBottom: '1px solid #CDCDCD'}}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} style={{textAlign: 'center', color: '#9E9E9E', fontWeight: 'normal', fontSize: '14px'}}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="tableRow" style={{textAlign: 'center'}}>
              {row.cells.map((cell, index) => {
                if(0 === index)
                    return <td style={{color: 'black', fontWeight: 'bold', marginRight: 1}} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                else if (4 === index)
                    return <td style={{color: cell.value === 'Low' ? '#275DF6' : 'red', fontWeight: 'bold'}} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                else if (5 === index)
                    return <td {...cell.getCellProps()}>
                      <ProgressBar 
                        className="progressBar"
                        completed={cell.value}
                        bgColor="linear-gradient(to right, #275DF6, #1D9FF8)"
                        labelAlignment="left"
                        // customLabelStyles={{position: 'absolute', left: '50%'}}
                      /></td>;
                else
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;